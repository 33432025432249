<template>
  <div>
    <van-cell-group title="筛选">
      <van-search v-model="sea.KEY" placeholder="请输入搜索关键字" @search="filter" @clear="filter"/>
      <van-field readonly label="分类" placeholder="选择分类筛选" is-link @click="cat.show = true" v-model="cat.txt"/>
      <van-popup v-model="cat.show" round position="bottom">
        <div class="btn">
          <div style="color: red" @click="cat.show = false">取消</div>
          <van-button type="info" size="small" @click="changeSel">确认</van-button>
        </div>
        <van-cascader title="请选择分类" :options="cat.ls" @change="catSel" v-model="sea.CAT_ID" :closeable="false"
                      :field-names="{ text: 'NAME', value: 'ID', children: 'CHILDREN' }"/>
      </van-popup>

      <van-field readonly label="仓库" placeholder="选择仓库筛选" is-link @click="ware.show = true" v-model="ware.txt"/>
      <van-popup v-model="ware.show" round position="bottom">
        <van-picker :columns="ware.lis" show-toolbar @cancel="ware.show = false" @confirm="changeWare"
                    value-key="STORAGE_NAME"/>
      </van-popup>
    </van-cell-group>
    <van-cell-group title="结果">
      <van-empty description="暂无数据" v-if="lst.da.length == 0"/>
      <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading">
        <van-card v-for="item in lst.da" :key="item.ID">
          <template #title>
            <van-cell style="background: transparent;padding: 0" :title="item.NAME" :value="item.BALANCE"
                      :label="item.CAT_NAME"/>
            <div style="text-align: right">
              <van-button size="small" style="margin-right: 10px" @click="onPictureClick(item)">详情</van-button>
              <van-button size="small" @click="onLossClick(item)">损耗</van-button>
            </div>
          </template>
          <template #thumb>
            <van-image :src="item.PIC_URL" style="width: 100%;height: 100%;vertical-align: middle"
                       v-if="item.PIC_NAME"/>
            <van-image :src="require('@/assets/qst_zwtp.png')" style="width: 100%;height: 100%;vertical-align: middle"
                       v-else/>
          </template>
        </van-card>
      </van-list>
    </van-cell-group>
    <van-popup v-model="picture.show" round position="bottom">
      <div>
        <van-image :src="picture.model.PIC_URL" v-if="picture.model.PIC_NAME"/>
        <div style="padding: 20px">
          <!--          <van-button type="primary" size="large" @click="submitPicture">上传图片</van-button>-->
          <van-uploader :after-read="afterRead" max-count="1" style="width: 100%">
            <van-button icon="plus" type="primary" size="large">上传图片</van-button>
          </van-uploader>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      sea: {
        PAGE_SIZE: 10,
        PAGE_INDEX: -1,
        KEY: "",
        CAT_ID: 0,
      },
      lst: {
        da: [],
        loading: false,
        finish: false,
      },
      cat: {
        show: false,
        ls: [],
        txt: "",
      },
      picture: {
        show: false,
        model: {},
      },
      ware: {
        txt: "",
        show: false,
        lis: [],
      },
    };
  },
  created() {
    this.getCat();
    this.getWare();
  },
  methods: {
    getCat() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.cat.ls = its.DATA;
        },
      });
    },
    getWare() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/MAT/MatStorage/GetPageList",
        completed: function (its) {
          self.ware.lis = its.DATA.ITEMS;
        },
      });
    },
    changeWare(e) {
      this.sea.STORAGE_ID = e.ID;
      this.ware.txt = e.STORAGE_NAME;
      this.ware.show = false;
      this.filter();
    },
    filter() {
      this.cat.show = false;
      this.lst.da.length = 0;
      this.lst.finish = false;
      this.sea.PAGE_INDEX = 0;
      this.getList();
    },
    loadPage() {
      this.sea.PAGE_INDEX += 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/MAT/MStockApi/GetList",
        data: this.sea,
        completed(its, n) {
          self.lst.da.push(...its.ITEMS);
          self.lst.loading = false;
          if (self.lst.da.length >= n) self.lst.finish = true;
        },
      });
    },
    catSel({selectedOptions}) {
      // this.cat.show = false;
      this.cat.txt = selectedOptions.map((option) => option.NAME).join("/");
      // this.filter();
    },
    changeSel() {
      this.cat.show = false;
      this.filter();
    },
    onPictureClick(it) {
      this.$router.push({path: "/mat/stock/edit", query: {id: it.ID}});
      // this.picture = {
      //   show: true,
      //   model: it
      // }
    },
    onLossClick(it) {
      this.$router.push({path: "/mat/stock/loss", query: {id: it.ID}});
    },
    afterRead(f) {
      let self = this;
      this.whale.image.compress({
        file: f.file,
        callback(f) {
          self.whale.image.toB64({
            file: f,
            callback(b64) {
              self.picture.model.PIC_B64 = b64;
              self.whale.remote.getResult({
                url: "/api/Mobile/MAT/MStockApi/UpdateImage",
                data: self.picture.model,
                completed(m) {
                  Object.assign(self.picture, {
                    show: false,
                    model: m.DATA,
                  });
                  for (let i = 0; i < self.lst.da.length; i++) {
                    let o = self.lst.da[i];
                    if (o.ID == m.DATA.ID) {
                      Object.assign(o, m);
                      break;
                    }
                  }
                },
              });
            },
          });
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .van-uploader__input-wrapper {
  flex: auto;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.card-content {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 88px;

  .title {
    display: flex;
    justify-content: space-between;

    .t1 {
      overflow: ;
    }
  }
}
</style>
